import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import { Container, Layout } from 'layout';

import { useCurrentLang } from 'hooks/useCurrentLang';

import { NotFoundQueryResult } from './model';

import './404.scss';

const NotFoundPage = () => {
  const {
    currentLang: [currentLangCode],
  } = useCurrentLang();

  const {
    allPageNotFound: { nodes },
    pageNotFound,
  }: NotFoundQueryResult = useStaticQuery(graphql`
    {
      allPageNotFound {
        nodes {
          lang
          text
        }
      }
      pageNotFound {
        text
      }
    }
  `);

  const { text } = nodes.find((item) => item.lang === currentLangCode) || pageNotFound;

  return (
    <Layout>
      <section className="not-found container-wrapper">
        <div className="not-found__holder">
          <Container fluid>
            <div className="not-found__content">
              <p className="not-found__heading">{text}</p>
            </div>
          </Container>
        </div>
      </section>
    </Layout>
  );
};

export default NotFoundPage;
